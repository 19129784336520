.hm-rich-text a.rdw-dropdown-selectedtext {
  color: inherit;
  text-decoration: none;
}
.rich-text-select {
  outline: none;
  height: 22px;
  font-size: 12px;
  padding-top: 1px;
}

.rich-text-select:focus {
  border: 1px solid var(--bs-gray-400);
  box-shadow: none;
}

.hm-rch-text-toolbar {
  border: 1px solid var(--bs-gray-400);
  border-radius: 0.25rem;
}

.hm-rich-text-editor {
  border: 1px solid var(--bs-gray-400);
  min-height: 250px;
  border-radius: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
