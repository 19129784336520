@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --toastify-color-success: #198754 !important;
}

html, body {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  font-family: 'Work Sans', sans-serif;
}
#root {
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.no-link,
.no-link:hover {
  color: inherit;
  text-decoration: inherit;
}

.flex-1 {
  flex: 1;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.sortable-handle {
  cursor: grab;
}
.sortable-dragging {
  cursor: grabbing;
}

h2 {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  margin: 0;
  padding: 0;
}

.counter {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.btn-100 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border-radius: 6px;
}

.form-check-input:checked {
  background-color: #0C823C;
  border-color: #0C823C;
}

.opacity-05 {
  opacity: 0.5;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 220px;
}

.lang-active {
  height: 32px;
  width: 32px;
  background-color: var(--bs-primary);
  border-radius: 50%;
  text-transform: capitalize;
  color: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.lang-non-active {
  height: 32px;
  width: 32px;
  border: 1px solid var(--bs-primary);
  border-radius: 50%;
  text-transform: capitalize;
  color: var(--bs-primary);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.card-header-login {
  border-bottom: none;
}

/* Modal CSS */

.modal-backdrop {
  background-color: rgba(175, 175, 175);
}

.modal-backdrop.show {
  opacity: 0.8;
}

.modal-header {
  border-bottom: 1px solid #000;
}

.modal-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

.modal-body {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.modal-footer {
  border-top: none;
}

.react-datepicker-popper {
  /* NOTE: Fix overlaps with rich content */
  z-index: 2 !important;
}

.modal-fullscreen{
  height: max-content !important;
}