.StickyTable {
  border: 1px solid #dee2e6;
  border-radius: 6px;
  position: relative;
  overflow-y: auto;
}

.StickyTable a {
  color: #000000;
}

.StickyTable th {
  position: sticky;
  top: 0;
  background-color: white;
}

.StickyTable thead {
  border: 0;
}

.StickyTable thead > tr > th {
  color: black;
  border: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  background-color: #faf9f9;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 2;
}

.StickyTable thead > tr > :after {
  content: ' ';
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  border-bottom: 1px solid #dee2e6;
}

.StickyTable table > tbody tr:last-child > td {
  border-bottom: 0px !important;
}

.StickyTable table > tbody {
  border-top: 0 !important;
}

.StickyTable table {
  margin-bottom: 0 !important;
}