// SEE: https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss

$primary: #0a0048  !default;

// Override stuff like input box shadow
$component-active-bg: #0F00FF !default;

// Active pagination background
$pagination-active-bg: $primary !default;
$pagination-border-width: 0 !default;

// Buttons
$btn-border-radius: 6px !default;
$btn-border-radius-sm: 6px !default;
$btn-font-weight: 500;

// Breadcrumbs
$breadcrumb-margin-bottom: 0 !default;
$breadcrumb-divider-color: $primary !default;
$breadcrumb-active-color: black !default;
$breadcrumb-font-size: 24px !important;

// Admin SideBar
$admin-sidebar-bg: $primary !default;
$admin-sidebar-active-item-bg: #007cc2 !default;
$admin-sidebar-hover-item-bg: #737373 !default;
$admin-sidebar-tagline-color: white !default;
